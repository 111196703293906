import React, { useState, useContext, createContext } from 'react';

const KeywordsContext = createContext();

const KeywordsProvider = ({ children }) => {
  const [keywords, setKeywords] = useState([]);

  const toggleKeyword = (keyword) => {
    const index = keywords.indexOf(keyword)
    if (index === -1) {
      setKeywords(keywords.concat([keyword]))
    } else {
      const newKeywords = keywords.filter(k => k !== keyword)
      setKeywords(newKeywords)
    }
  };

  const resetKeywords = () => {
    setKeywords([])
  };

  const context = {
    toggleKeyword,
    keywords,
    resetKeywords,
  };

  return (
    <KeywordsContext.Provider value={context}>
      {children}
    </KeywordsContext.Provider>
  );
};

const useKeywords = () => useContext(KeywordsContext);

export { KeywordsProvider, useKeywords };
