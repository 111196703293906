import * as Yup from 'yup';
import {TOO_LONG, TOO_SHORT, INVALID_EMAIL, INVALID_PHONE, PHONE_REGEXP, TERMS_OF_SERVICE_NOT_ACCEPTED, REQUIRED} from './constants';

export const ValidationSchema = Yup.object().shape({
    Nome: Yup.string()
      .min(2, TOO_SHORT)
      .max(25, TOO_LONG)
      .required(REQUIRED),
    Cognome: Yup.string()
      .min(2, TOO_SHORT)
      .max(25, TOO_LONG),
    Telefono: Yup.string()
    .matches(PHONE_REGEXP, INVALID_PHONE),
    Provincia: Yup.string()
      .required(REQUIRED),
    Email: Yup.string()
      .email(INVALID_EMAIL)
      .max(50, TOO_LONG)
      .required(REQUIRED),
    Privacy: Yup.boolean()
      .required(REQUIRED)
      .oneOf([true], TERMS_OF_SERVICE_NOT_ACCEPTED),
  });