import union from 'lodash.union'
import intersection from 'lodash.intersection'

export const COURSES = [
  {
    id: '1',
    department: 'Dipartimento di Arti Visive',
    name: 'Decorazione',
    keywords: ['decorare', 'colore', 'disegno', 'creatività', 'progetto', 'sperimentare', 'mosaico', 'aerografo', 'tattoo'],
  },
  {
    id: '2',
    department: 'Dipartimento di Arti Visive',
    name: 'Decorazione - Interior design',
    keywords: ['decorare', 'colore', 'disegno', 'creatività', 'progetto', 'design', 'ambiente', 'luce', 'arredamento', 'disegno 3D'],
  },
  {
    id: '3',
    department: 'Dipartimento di Arti Visive',
    name: 'Grafica',
    keywords: ['creatività', 'disegno', 'comunicazione', 'illustrazione', 'fotografia', 'pubblicità', 'lettering', 'video', 'brand', 'web'],
  },
  {
    id: '4',
    department: 'Dipartimento di Arti Visive',
    name: 'Scultura',
    keywords: ['scolpire', 'dipingere', 'creta', 'marmo', 'disegno', 'forme', 'creare', 'storia dell\'arte', 'pittura', 'tela'],
  },
  {
    id: '5',
    department: 'Dipartimento di Arti Visive',
    name: 'Pittura',
    keywords: ['dipingere', 'scolpire', 'tela', 'disegno', 'ritratto', 'illustrazione', 'colore', 'paesaggio', 'storia dell\'arte', 'creatività'],
  },
  {
    id: '6',
    department: 'Dipartimento di Comunicazione e Didattica dell\'arte',
    name: 'Comunicazione e valorizzazione del patrimonio artistico',
    keywords: ['storia dell\'arte', 'beni culturali', 'mostra', 'marketing', 'comunicazione', 'eventi', 'social', 'pubbliche relazioni', 'creatività', 'museo'],
  },
  {
    id: '7',
    department: 'Dipartimento di Comunicazione e Didattica dell\'arte',
    name: 'Didattica dell\'arte per i musei',
    keywords: ['storia dell\'arte', 'museo', 'pedagogia', 'beni culturali', 'multimedialità', 'comunicazione', 'eventi', 'social', 'creatività', 'mostra'],
  },
  {
    id: '8',
    department: 'Dipartimento di Progettazione e Arti Applicate',
    name: 'Scenografia',
    keywords: ['teatro', 'spettacolo', 'televisione', 'allestimento', 'luce', 'suono', 'creatività', 'regista', 'costume', 'immaginazione'],
  },
  {
    id: '9',
    department: 'Dipartimento di Progettazione e Arti Applicate',
    name: 'Nuove tecnologie dell\'arte',
    keywords: ['videogioco', 'video', 'fotografia', 'spazio virtuale', 'effetto sonoro', 'regista', 'tecnologia', 'creatività', 'pubblicità', 'digital art'],
  },
  {
    id: '10',
    department: 'Dipartimento di Progettazione e Arti Applicate',
    name: 'Web e comunicazione d\'impresa',
    keywords: ['comunicazione', 'web designer', 'social', 'pubblicità', 'fotografia', 'marketing', 'creatività', 'siti internet', 'strategia', 'brand'],
  },
]

export const KEYWORDS = COURSES.reduce((acc, { keywords = [] }) => {
  return union(acc, keywords)
}, [])

export const getCourse = (id, keywords = []) => {
  const course = COURSES.find(course => course.id === id)
  return {
    ...course,
    ...calculateMatch(course, keywords)
  }
}

const calculateMatch = (course = {}, keywords = []) => {
  const courseKeywords = course.keywords || []
  const keywordsMatched = intersection(courseKeywords, keywords)
  return {
    match: parseInt(keywordsMatched.length / courseKeywords.length * 100),
    firstMatch: keywordsMatched.length === 0 ? -1 : courseKeywords.indexOf(keywordsMatched[0])
  }
}

/*
  Funzionamento: 
  - Ritorna i corsi con almeno una parola in comune.
  - Vengono ordinate in base al numero di parole matchate. 
  - A parità di parole matchate, vince il corso con la prima parola matchate in ordine di posizione

  Ritorna:
  [
    {
      name: 'Decorazione',
      match: 80, // percentuale di parole trovate 
      firstMatch: 1, // indice della prima parola trovata
    }
  ]
*/
export const getCourses = (keywords = []) => {
  return COURSES
    .map(course => {      
      return {
        id: course.id,
        name: course.name,
        ...calculateMatch(course, keywords),
      }
    })
    .filter(({ match }) => match > 0)
    .sort((a, b) => {
      if (a.match - b.match > 0) return -1
      else if (a.match - b.match < 0) return 1
      else {
        if (a.firstMatch - b.firstMatch > 0) return 1
        else if (a.firstMatch - b.firstMatch < 0) return -1
        return 0
      }
    })
    .map(({ id, name, match }) => ({ id, name, match }))
}
