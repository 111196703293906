import './Variables.scss';
import variables from './variables';
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    height: 100vh;
  }
`

// --------------------- NavBar

export const NavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 20px 0px;
  background-color: ${variables.background};
  z-index: 2;
  .logo-hdemia{
    width: 150px;
    max-width: 100%;
    height: 100%;
  }
  &.bg-trasparent{
    background-color: transparent
  }
`
// --------------------- Content

export const ScrollContentBody = styled.div`
  width: 100%;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  &.welcome{
    padding-top: 107px;
    h1{
      font-size: 14px;
    }
    @media only screen and (max-height: 667px){
      h1{
        font-size: 12px;
      }
    }
    @media only screen and (min-height: 880px){
      h1{
        margin-top: 12%;
        font-size: 25px;
      }
    }
  }
  &.quiz{
    padding-top: 133px;
    padding-bottom: 96px;
  }
  &.courses{
    padding-top: 122px;
    padding-bottom: 162px;
  }
  &.detail-course{
    padding-bottom: 16px;
    padding-top: 64px;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: 0px none transparent;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:active {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none transparent;
    border-radius: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`

export const Container = styled.div`
  position: relative; 
  margin: auto;
  height: auto;
  padding: 0 16px;
  @media only screen and (min-width: 768px){
    width: 720px;
  }
  @media only screen and (min-width: 992px){
    width: 960px;
  }
  @media only screen and (max-width: 375px){
    padding: 0 8px;
  }
`

// --------------------- Footer

export const Footer = styled.footer`
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 16px;
  z-index: 2;
  &.no-padding{
    padding: 0;
    svg{
      max-width: 400px;
      margin: auto;
    }
    .blue-footer{
      background-color: ${variables.darkBlue};
      padding-bottom: 0;
      padding: 0;
      p{
        font-size: 11px;
        margin-bottom: 0;
        text-align: center;
        padding: 50px 0 30px;
        font-weight: 500;
        color: ${variables.white};
        margin: auto;
      }
      @media only screen and (max-height: 605px){
        p{
          padding: 20px 0 20px;
        }
      }
    }
    @media only screen and (max-height: 760px){
      svg{
        max-width: inherit;
        max-height: 240px;
      }
      .blue-footer{
        p{
          font-size: 14px;
        }
      }
    }
    @media only screen and (max-height: 545px){
      svg{
        max-height: 200px;
      }
    }
    @media only screen and (max-height: 498px){
      svg{
        max-height: 150px;
      }
    }
    @media only screen and (min-height: 880px){
      svg{
        max-width: 500px;
      }
    }
  }
  &.quiz-footer{
    background-color: ${variables.background};
    padding: 0;
    -webkit-box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
    -moz-box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
    box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
    .cont-footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
  }
  &.bg-light{
    background-color: ${variables.background};
    -webkit-box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
    -moz-box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
    box-shadow: 0px -20px 20px 0px rgba(250,250,250,1);
  }
`

// --------------------- Buttons

export const ButtonLogin = styled.button`
  background-color: ${variables.darkYellow};
  padding: 12px 40px;
  border-radius: 30px;
  color: ${variables.black};
  border: 2px solid ${variables.darkYellow};
  font-weight: 500;
  font-size: 18px;
  svg{
    width: 40px;
    margin-left: 5px;
  }
  &:hover,
  &:focus,
  &:active{
    background-color: ${variables.yellow};
    border-color: ${variables.yellow};
  }
  @media only screen and (max-height: 760px){
    padding: 8px 35px;
    font-size: 16px;
  }
`

export const ButtonPlay = styled(ButtonLogin)`
  svg{
    fill: ${variables.black};
    width: 20px;
  }
`

export const ButtonPrev = styled.button`
  border: 2px solid ${variables.darkYellow};
  background-color: ${variables.background};
  color: ${variables.darkYellow};
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 30px;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 40px;
  svg{
    width: 20px;
    fill: ${variables.darkYellow};
    margin-rigth: 5px;
    transition: 0.2s ease-in-out;
  }
  &:hover,
  &:active,
  &:focus{
    background-color: ${variables.darkYellow};
    color: ${variables.white};
    svg{
      fill: ${variables.white};
    }
  }
`

export const ButtonNext = styled(ButtonPrev)`
  border-top-right-radius: 0px;
  border-top-left-radius: 40px;    
  padding-left: 27px;
  padding-right: 27px;
  svg{
    margin-left: 5px;
  }
`

export const BackButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  svg{
    width: 40px;
    height: auto;
  }
`

// --------------------- Text

export const H1Styled = styled.h1`
  font-weight: 600;
  font-size: 22px;
  color: ${variables.grey};
  @media only screen and (max-height: 760px){
    font-size: 20px;
  }
  &.courses-style{
    margin-bottom: 0;
    margin-top: 30px;
  }
  &.resp-h1{
    font-size: 18px;
    @media only screen and (max-width: 363px){
      font-size: 12px;
    }
  }
`

export const PStyled = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${variables.grey};
  &.alert-message{
    color: ${variables.red};
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
  }
`


// --------------------- Spacing

export const MarginTB30 = styled.div`
  margin: 30px 0;
  @media only screen and (max-height: 760px){
    margin: 20px 0;
  }
`
export const MarginCarousel = styled.div`
  margin-bottom: 20px;
`

// --------------------- Quiz Page

export const ContFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContFlexRight = styled(ContFlexCenter)`
  align-items: flex-end;
  justify-content: flex-end;
  a {
    display: contents;
  }
`

export const ContFlexLeft = styled(ContFlexCenter)`
  align-items: flex-start;
  justify-content: flex-start;
  a {
    display: contents;
  }
`

export const CounterIndicator = styled.h3`
  margin-top: 15px;
  color: ${variables.blue};
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 500;
  .badge{
    color: ${variables.white};
    background-color: ${variables.blue};
    font-size: 28px;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
`

export const CheckboxPreference = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid ${variables.darkYellow};
  border-radius: 30px;
  margin: auto;
  margin-bottom: 20px;
  padding: 6px 20px;
  font-weight: 800;
  font-size: 18px;
  text-transform: capitalize;
  background-color: ${variables.background};
  &:first-child{
    margin-top: 20px;
  }
  .cont-check{
    min-width: 30px; 
    height: 30px;
    border: 2px solid ${variables.darkYellow};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    svg{
      width: 15px;
      height: 15px;
      display: none;
    }
  }
  &.active{
    border-color: ${variables.green};
    .cont-check{
      border-color: ${variables.green};
      svg{
        display: inline;
      }
    }
  }
  &.animated{
    animation: wobble 0.4s 1;
  }
  @media only screen and (min-width: 769px){
    width: 350px;
  }
  @media only screen and (max-width: 768px){
    font-size: 14px;
  }
  @media only screen and (max-width: 420px){
    font-size: 12px;
    padding: 6px 8px;
    width: 162px;
    margin-bottom: 10px;
    .cont-check{
      min-width: 25px; 
      height: 25px;
      margin-right: 6px;
    }
  }
  @media only screen and (max-width: 375px){
    width: 158px;
  }
  @media only screen and (max-width: 320px){
    font-size: 10px;
    width: 140px;
    padding: 6px 4px;
  }
`

// COURSE DETAIL PAGE

export const ContCourseInfo = styled.div`
  width: 100%;
  height: auto;
  background-color: ${variables.background};
  .detail-container{
    padding: 0;
    p{
      margin-bottom: 0px;
      padding: 0 16px;
    }
    h1{
      margin-bottom: 20px;
      padding: 0 16px;
      font-size: 25px;
      @media only screen and (max-height: 760px){
        font-size: 25px;
      }
    }
    .progress{
      border-radius: 0;
      height: 12px;
      background-color: #CFE1FC;
      .progress-bar{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: ${variables.blue};
      }
    }
  }
`

export const KeywordCont = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .keyword{
    background-color: ${variables.background};
    text-transform: uppercase;
    padding: 10px 40px;
    width: calc(100% - 50px);
    border-radius: 30px;
    border-bottom-left-radius: 0px; 
    border: 2px solid ${variables.darkYellow};
    font-weight: 500;
    color: ${variables.black};
    font-size: 18px;
  }
  svg{
    &.check{
      width: 30px;
      fill: ${variables.greenWater};
    }
    &.cross{
      width: 25px;
      fill: ${variables.darkYellow};
    }
  }
  &.active{
    .keyword{
      border-color: ${variables.greenWater};
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
`

//  FORM PAGE

export const FormContactsCont = styled.div`
  max-width: 500px;
  margin: auto;
  .form-group{
    input{
      border-radius: 50px;
      padding: 8px 16px;
      &:focus{
        border-color: ${variables.grey};
        box-shadow: none;
      }
    }
    select {
      border-radius: 50px;
    }
    .form-message{
      font-size: 13px;
      color: ${variables.red};
    }
  }
`