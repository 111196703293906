import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as IconHourglass } from "../images/icn_hourglass.svg";
import { H1Styled } from "../styled-components";

function ModalLoading({ loading, message }) {
	const messageToDisplay = message || "Stiamo calcolando...";
	return (
		<Modal isOpen={loading} centered={true}>
			<ModalBody>
				<div className="u-center-center cont-spinner">
					<H1Styled>{messageToDisplay}</H1Styled>
					<IconHourglass className="spin" />
				</div>
			</ModalBody>
		</Modal>
	);
}

export default ModalLoading;
