import { React, useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  NavBar,
  ScrollContentBody,
  Footer,
  Container,
  MarginTB30,
  H1Styled,
  PStyled,
  ButtonPlay,
  ContFlexRight,
  ContFlexLeft,
} from '../styled-components'
import { ReactComponent as IconReload } from '../images/icn_reload.svg'
import { ReactComponent as IconApe } from '../images/icn_ape.svg'
import { ReactComponent as Line } from '../images/line.svg'
import { useKeywords } from '../providers/keywordsProviders'
import { Progress } from 'reactstrap'
import { getCourses } from '../keywords'
import ReactPixel from 'react-facebook-pixel'
import { useCookies } from 'react-cookie'

const COLORS = ['green-water', 'yellow', 'blue']

const advancedMatching = { em: 'some@email.com' }
const options = {
  autoConfig: true,
  debug: true,
}

function Courses() {
  const [cookies] = useCookies(['cookie-name'])
  const hasMarketingPreference = cookies['rcl_marketing_consent'] || false
  const { keywords = [] } = useKeywords()
  const courses = getCourses(keywords)

  const [homeUrl, setHomeUrl] = useState('/')

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem('token'))
    if (token) {
      setHomeUrl('/?token=a8cv212edAml2')
    }
  }, [])
  useEffect(() => {
    ReactPixel.init('294830575404485', advancedMatching, options)
    if (hasMarketingPreference) {
      ReactPixel.pageView()
    } else {
      ReactPixel.revokeConsent()
      console.log('visualizzo la pagina')
    }
  }, [])

  useEffect(() => {
    if (hasMarketingPreference) {
      ReactPixel.grantConsent()
    }
  }, [hasMarketingPreference])

  if (keywords.length === 0) {
    return <Redirect to='/' />
  }

  return (
    <>
      <ScrollContentBody className='courses'>
        <NavBar>
          <Container className='text-center'>
            {/* <IconApe className='shake ape-position' />
            <Line className='line-position' /> */}
            <H1Styled className='courses-style'>
              Ecco i percorsi
              <br />
              che puoi esplorare
            </H1Styled>
          </Container>
        </NavBar>

        <Container>
          <MarginTB30>
            {courses.map((course, i) => {
              const { id, name, match } = course
              const color = COLORS[i % 3]
              const Cont = i % 2 === 0 ? ContFlexLeft : ContFlexRight
              return (
                <Cont key={i}>
                  <Link to={`/course-detail/${id}`} className='course-progress'>
                    <Progress value={match} className={color}>
                      <p
                        className='u-center-center'
                        style={{ whiteSpace: 'pre-line', width: '100%' }}
                      >
                        {name}
                      </p>
                    </Progress>
                  </Link>
                </Cont>
              )
            })}
          </MarginTB30>
        </Container>

        <Footer className='bg-light text-center'>
          <PStyled style={{ marginBottom: '5px' }}>Sei incuriosito?</PStyled>
          <Link to={homeUrl}>
            <ButtonPlay type='button'>
              Gioca di nuovo <IconReload />
            </ButtonPlay>
          </Link>
          <PStyled style={{ margin: '20px 0 0' }}>
            <i>
              Commenta gli esiti con lo staff Orientamento o contatta{' '}
              <a href='mailto:orientamento@accademiasantagiulia.it'>
                orientamento@accademiasantagiulia.it
              </a>
            </i>
          </PStyled>
        </Footer>
      </ScrollContentBody>
    </>
  )
}

export default Courses
