import React, { } from "react";
import { useCookies } from 'react-cookie';
import { NavBar, ScrollContentBody, Container, H1Styled, } from "../styled-components";
import { FormContacts } from '../components/form';

function Contacts() {
  // const [cookies] = useCookies(['cookie-name']);
  // const hasMarketingPreference = cookies['rcl_marketing_consent'] || false;

  return (
    <>
      <ScrollContentBody className="courses">
        <NavBar>
          <Container className="text-center">
            <H1Styled className="courses-style">Inserisci i tuoi dati e scopri quali mondi dell’arte potresti esplorare!</H1Styled>
          </Container>
        </NavBar>

        <FormContacts />

      </ScrollContentBody>
    </>
  );
}

export default Contacts