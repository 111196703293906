import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Form, FormGroup, Input, CustomInput } from "reactstrap";
import Error from "../Errors";
import {
	Footer,
	FormContactsCont,
	ButtonPlay,
	Container,
	MarginTB30,
} from "../styled-components";
import { useKeywords } from "../providers/keywordsProviders";
import { ValidationSchema } from "../validationSchema";
import { actualDate } from "../actualDate";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getCourses } from "../keywords";
import { API_URL, INTERNAL_ERROR } from "../constants";
import ModalLoading from "../components/modalLoading";

export function FormContacts() {
	const [loading, setLoading] = useState(false);
	const [contactSent, setContactSent] = useState(false);
	const { keywords = [] } = useKeywords();

	const courses = getCourses(keywords)
		.map((r) => r.name)
		.join(", ");
	const keywordsList = useKeywords();
	const sendKeywords = keywordsList.keywords.join(", ");
	let history = useHistory();

  useEffect(()=>{
    if (setContactSent) {
      window.localStorage.setItem("token", true)
    }
  }, [contactSent])

	return (
		<>
			<Formik
				initialValues={{
					Nome: "",
					Cognome: "",
					Email: "",
					Telefono: "",
					Provincia: "",
					Keywords: sendKeywords,
					Risultato: courses,
					Data: actualDate,
					Privacy: false,
					Marketing: false,
				}}
				validationSchema={ValidationSchema}
				validate={(values) => {
					let errors = {};
					return errors;
				}}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					setLoading(true);
					axios
						.post(API_URL, values)
						.then((response) => {
							resetForm();
							setSubmitting(false);
							setLoading(false);
							setContactSent(true)
							history.push("/courses");
						})
						.catch((error) => {
							setSubmitting(false);
							setLoading(false);
							alert(INTERNAL_ERROR);
							console.log(error);
						});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Container>
							<MarginTB30>
								<FormContactsCont>
									<FormGroup>
										<Input
											type="text"
											name="Nome"
											placeholder="Nome"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Nome}
											className={
												touched.Nome && errors.Nome ? "has-error" : null
											}
										/>
										<Error touched={touched.Nome} message={errors.Nome} />
									</FormGroup>

									<FormGroup>
										<Input
											type="text"
											name="Cognome"
											placeholder="Cognome"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Cognome}
											className={
												touched.Cognome && errors.Cognome ? "has-error" : null
											}
										/>
										<Error touched={touched.Cognome} message={errors.Cognome} />
									</FormGroup>

									<FormGroup>
										<Input
											type="text"
											name="Email"
											placeholder="Email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Email}
											className={
												touched.Email && errors.Email ? "has-error" : null
											}
										/>
										<Error touched={touched.Email} message={errors.Email} />
									</FormGroup>

									<FormGroup>
										<Input
											type="tel"
											name="Telefono"
											placeholder="Telefono"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Telefono}
											className={
												touched.Telefono && errors.Telefono ? "has-error" : null
											}
										/>
										<Error
											touched={touched.Telefono}
											message={errors.Telefono}
										/>
									</FormGroup>

									<FormGroup>
										<Input
											type="select"
											name="Provincia"
											id="Provincia"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Provincia}
											className={
												touched.Provincia && errors.Provincia
													? "has-error"
													: null
											}
										>
											<option value="" label="Inserisci la tua provincia" />
											<option value="ag">Agrigento</option>
											<option value="al">Alessandria</option>
											<option value="an">Ancona</option>
											<option value="ao">Aosta</option>
											<option value="ar">Arezzo</option>
											<option value="ap">Ascoli Piceno</option>
											<option value="at">Asti</option>
											<option value="av">Avellino</option>
											<option value="ba">Bari</option>
											<option value="bt">Barletta-Andria-Trani</option>
											<option value="bl">Belluno</option>
											<option value="bn">Benevento</option>
											<option value="bg">Bergamo</option>
											<option value="bi">Biella</option>
											<option value="bo">Bologna</option>
											<option value="bz">Bolzano</option>
											<option value="bs">Brescia</option>
											<option value="br">Brindisi</option>
											<option value="ca">Cagliari</option>
											<option value="cl">Caltanissetta</option>
											<option value="cb">Campobasso</option>
											<option value="ci">Carbonia-iglesias</option>
											<option value="ce">Caserta</option>
											<option value="ct">Catania</option>
											<option value="cz">Catanzaro</option>
											<option value="ch">Chieti</option>
											<option value="co">Como</option>
											<option value="cs">Cosenza</option>
											<option value="cr">Cremona</option>
											<option value="kr">Crotone</option>
											<option value="cn">Cuneo</option>
											<option value="en">Enna</option>
											<option value="fm">Fermo</option>
											<option value="fe">Ferrara</option>
											<option value="fi">Firenze</option>
											<option value="fg">Foggia</option>
											<option value="fc">Forl&igrave;-Cesena</option>
											<option value="fr">Frosinone</option>
											<option value="ge">Genova</option>
											<option value="go">Gorizia</option>
											<option value="gr">Grosseto</option>
											<option value="im">Imperia</option>
											<option value="is">Isernia</option>
											<option value="sp">La spezia</option>
											<option value="aq">L'aquila</option>
											<option value="lt">Latina</option>
											<option value="le">Lecce</option>
											<option value="lc">Lecco</option>
											<option value="li">Livorno</option>
											<option value="lo">Lodi</option>
											<option value="lu">Lucca</option>
											<option value="mc">Macerata</option>
											<option value="mn">Mantova</option>
											<option value="ms">Massa-Carrara</option>
											<option value="mt">Matera</option>
											<option value="vs">Medio Campidano</option>
											<option value="me">Messina</option>
											<option value="mi">Milano</option>
											<option value="mo">Modena</option>
											<option value="mb">Monza e della Brianza</option>
											<option value="na">Napoli</option>
											<option value="no">Novara</option>
											<option value="nu">Nuoro</option>
											<option value="og">Ogliastra</option>
											<option value="ot">Olbia-Tempio</option>
											<option value="or">Oristano</option>
											<option value="pd">Padova</option>
											<option value="pa">Palermo</option>
											<option value="pr">Parma</option>
											<option value="pv">Pavia</option>
											<option value="pg">Perugia</option>
											<option value="pu">Pesaro e Urbino</option>
											<option value="pe">Pescara</option>
											<option value="pc">Piacenza</option>
											<option value="pi">Pisa</option>
											<option value="pt">Pistoia</option>
											<option value="pn">Pordenone</option>
											<option value="pz">Potenza</option>
											<option value="po">Prato</option>
											<option value="rg">Ragusa</option>
											<option value="ra">Ravenna</option>
											<option value="rc">Reggio di Calabria</option>
											<option value="re">Reggio nell'Emilia</option>
											<option value="ri">Rieti</option>
											<option value="rn">Rimini</option>
											<option value="rm">Roma</option>
											<option value="ro">Rovigo</option>
											<option value="sa">Salerno</option>
											<option value="ss">Sassari</option>
											<option value="sv">Savona</option>
											<option value="si">Siena</option>
											<option value="sr">Siracusa</option>
											<option value="so">Sondrio</option>
											<option value="ta">Taranto</option>
											<option value="te">Teramo</option>
											<option value="tr">Terni</option>
											<option value="to">Torino</option>
											<option value="tp">Trapani</option>
											<option value="tn">Trento</option>
											<option value="tv">Treviso</option>
											<option value="ts">Trieste</option>
											<option value="ud">Udine</option>
											<option value="va">Varese</option>
											<option value="ve">Venezia</option>
											<option value="vb">Verbano-Cusio-Ossola</option>
											<option value="vc">Vercelli</option>
											<option value="vr">Verona</option>
											<option value="vv">Vibo valentia</option>
											<option value="vi">Vicenza</option>
											<option value="vt">Viterbo</option>
										</Input>

										<Error
											touched={touched.Provincia}
											message={errors.Provincia}
										/>
									</FormGroup>

									<FormGroup>
										<div>
											<CustomInput
												type="checkbox"
												name="Privacy"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.Privacy}
												className={
													touched.Privacy && errors.Privacy ? "has-error" : null
												}
												label={
													<>
														Ho preso visione dell'informativa sul trattamento
														dei dati personali ai sensi degli artt. 13 e 14 del
														Regolamento (UE) 2016/679 (GDPR) -{" "}
														<a
															href={process.env.PUBLIC_URL + '/docs/Informativa_progetto_Orientamento_Accademia_SantaGiulia.pdf'}
															target="_blank" rel="noreferrer"
														>
															Privacy policy
														</a>
													</>
												}
												id="privacyCheckbox"
											/>
										</div>
										<Error touched={touched.Privacy} message={errors.Privacy} />
									</FormGroup>

									<FormGroup>
										<div>
											<CustomInput
												type="checkbox"
												name="Marketing"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.Marketing}
												label="Acconsento a ricevere newsletter e comunicazioni relative a iniziative collegate alle attività del Gruppo Foppa"
												id="marketingCheckbox"
											/>
										</div>
									</FormGroup>

									<Input
										type="hidden"
										name="Keywords"
										value={values.Keywords}
									/>
									<Input
										type="hidden"
										name="Risultato"
										value={values.Risultato}
									/>

									<Input type="hidden" name="Data" value={values.Data} />
								</FormContactsCont>
							</MarginTB30>
						</Container>

						<Footer className="bg-light text-center">
							<ButtonPlay type="submit">Scopri il risultato</ButtonPlay>
						</Footer>
					</Form>
				)}
			</Formik>
			<ModalLoading loading={loading} messagge="" />
		</>
	);
}
