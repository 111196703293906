export const TOO_SHORT = "Inserisci qualche carattere";
export const TOO_LONG = "Hai superato il limite di caratteri";
export const REQUIRED = "Questo valore è obbligatorio";
export const INVALID_PHONE = "Numero di telefono non valido";
export const INVALID_EMAIL = "Indirizzo email non valido";
export const TERMS_OF_SERVICE_NOT_ACCEPTED = "Per poter vedere il risultato è obbligatorio accettare i nostri termini e condizioni.";

export const API_URL = process.env.REACT_APP_API_URL;
export const INTERNAL_ERROR = "Qualcosa è andato storto, riprova più tardi."

export const PHONE_REGEXP = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

