import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
	NavBar,
	ScrollContentBody,
	Footer,
	Container,
	MarginCarousel,
	CounterIndicator,
	CheckboxPreference,
	H1Styled,
	ButtonPrev,
	ButtonNext,
	PStyled,
} from "../styled-components";
import { ReactComponent as IconCheck } from "../images/icn_check.svg";
import { ReactComponent as IconRight } from "../images/icn_right_small.svg";
import { ReactComponent as IconLeft } from "../images/icn_left_small.svg";
import { Carousel, CarouselItem, Badge, Row, Col } from "reactstrap";
import { KEYWORDS } from "../keywords";
import chunk from "lodash.chunk";
import { useKeywords } from "../providers/keywordsProviders";
import { useSwipeable } from "react-swipeable";
import { useDebouncedCallback } from "use-debounce";
import ModalLoading from "../components/modalLoading";
import shuffle from "lodash.shuffle";

const PREFIX_ID = "pref-";
const getKeywordByTarget = (target) => {
	const { id = "", parentElement } = target || {};
	if (id.startsWith(PREFIX_ID)) {
		return id.replace(PREFIX_ID, "");
	}
	if (parentElement) {
		return getKeywordByTarget(parentElement);
	}
	return "";
};

function Quiz({ history }) {
	const { keywords = [], toggleKeyword, resetKeywords } = useKeywords();
	const [animatedKeywords, setAnimatedKeywords] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [animatingCarousel, setAnimatingCarousel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

	const [token, setToken] = useState(false);
	useEffect(() => {
		setToken(JSON.parse(window.localStorage.getItem("token")));
	}, []);

	const debounced = useDebouncedCallback(
		(keyword) => {
			setAnimatedKeywords(animatedKeywords.concat([keyword]));
			toggleKeyword(keyword);
		},
		200,
		{ leading: true, trailing: false },
	);

	const handlers = useSwipeable({
		trackMouse: true,
		onSwipedLeft: () => {
			if (activeIndex < items.length - 1) {
				next();
			}
		},
		onSwipedRight: () => previous(),
		onTap: ({ event }) => {
			const keyword = getKeywordByTarget(event.target);
			if (keyword) {
				debounced.callback(keyword);
			}
		},
	});

	useEffect(() => {
		resetKeywords();
		setItems(chunk(shuffle(KEYWORDS), 12));
	}, []);

	const next = () => {
		if (animatingCarousel) return;
		if (activeIndex < items.length - 1) {
			setActiveIndex(activeIndex + 1);
			return;
		}
		if (keywords.length > 0 && !token) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				history.push("/contacts");
			}, 2000);
		} else if (keywords.length > 0 && token) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				history.push("/courses");
			}, 2000);
		}
	};

	const previous = () => {
		if (animatingCarousel || activeIndex === 0) return;
		setActiveIndex(activeIndex - 1);
	};

	return (
		<>
			<ScrollContentBody className="quiz">
				<NavBar className="text-center noselect">
					<CounterIndicator>
						<Badge className="noselect">{activeIndex + 1}</Badge>{" "}
						<small>di</small> {items.length}
					</CounterIndicator>
					<H1Styled className="m-b-0 noselect p-lr-16 resp-h1">
						Scegli le parole che ti colpiscono di più:
					</H1Styled>
				</NavBar>

				<Container {...handlers}>
					<MarginCarousel>
						<Carousel
							activeIndex={activeIndex}
							next={next}
							previous={previous}
							interval={false}
						>
							{items.map((item, i) => (
								<CarouselItem
									onExiting={() => setAnimatingCarousel(true)}
									onExited={() => setAnimatingCarousel(false)}
									key={i}
								>
									<Row style={{justifyContent: "center"}} className="checkboxRow">
										{item.map((keyword) => {
											const active = keywords.includes(keyword) ? "active" : "";
											const animated = animatedKeywords.includes(keyword)
												? "animated"
												: "";
											return (
												
												<Col xs="5">
													<CheckboxPreference
														id={`${PREFIX_ID}${keyword}`}
														key={keyword}
														className={`noselect ${active} ${animated}`}
														onAnimationEnd={() =>
															setAnimatedKeywords(
																animatedKeywords.filter((k) => k !== keyword),
															)
														}
													>
														<div className="cont-check noselect">
															<IconCheck />
														</div>
														{keyword}
													</CheckboxPreference>
												</Col>
												
											);
										})}
									</Row>
								</CarouselItem>
							))}
						</Carousel>
					</MarginCarousel>
				</Container>

				<Footer className="quiz-footer">
					{activeIndex === items.length - 1 && keywords.length === 0 && (
						<PStyled className="alert-message">
							Seleziona almeno una parola per poter proseguire
						</PStyled>
					)}
					<Container className="cont-footer">
						<ButtonPrev type="button" onClick={previous}>
							<IconLeft /> prev
						</ButtonPrev>
						<ButtonNext onClick={next}>
							{activeIndex === items.length - 1 ? (
								<>Concludi</>
							) : (
								<>
									next <IconRight />
								</>
							)}
						</ButtonNext>
					</Container>
				</Footer>
			</ScrollContentBody>

			<ModalLoading loading={loading} />
		</>
	);
}

export default withRouter(Quiz);
