import React from 'react'
import { CookieBanner as ReactCookieLaw } from '@palmabit/react-cookie-law'

const CookieBanner = () => {
  return (
    <div>
      {
        process.browser &&

        <ReactCookieLaw
          message="Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. Per saperne di più o negare il consenso, consulta la cookie policy. Accettando acconsenti all’uso dei cookie."
          acceptButtonText="Accetta"
          managePreferencesButtonText="Personalizza"
          savePreferencesButtonText="Salva preferenze"
          necessaryOptionText="Necessari"
          statisticsOptionText="Statistiche"
          marketingOptionText="Marketing"
          privacyPolicyLinkText="Cookie Policy"
          policyLink="https://wordsmatch.accademiasantagiulia.it/docs/Informativa_progetto_Orientamento_Accademia_SantaGiulia.pdf"
          showPreferencesOption={false}
          showStatisticsOption={false}
          showMarketingOption={true}
          dismissOnScroll={false}
          styles={{
            dialog: {
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              zIndex: '100000',
              backgroundColor: '#272343',
              padding: '18px',
              fontSize: '14px'
            },
            button: {
              backgroundColor: '#F1FCFC',
              color: '#272343',
              padding: '4px 16px',
              border: 'none',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            },
            message: {
              color: '#F1FCFC'
            },
            policy: {
              color: '#F1FCFC',
              textDecoration: 'underline'
            },
            optionWrapper: {
              display: 'inline-block',
              position: 'relative',
              marginRight: '7px',
              marginLeft: '0px',
              float: 'left'
            }


          }}
        />
      }
    </div>
  )
}

export default CookieBanner
