import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { Progress } from "reactstrap";
import { getCourse } from "../keywords";
import { useKeywords } from "../providers/keywordsProviders";
import { BackButton, Container, H1Styled, MarginTB30, NavBar, PStyled, ScrollContentBody, KeywordCont, ContCourseInfo } from "../styled-components";
import { ReactComponent as IconLeft } from '../images/icn_left_big.svg';
import { ReactComponent as IconCross } from '../images/icn_cross_orange.svg';
import { ReactComponent as IconCheck } from '../images/icn_check_greenwater.svg';

function CourseDetail() {
  const { id } = useParams()
  const { keywords = [] } = useKeywords()
  const course = getCourse(id, keywords)

  if (keywords.length === 0) {
    return <Redirect to="/" />
  }

  const {
    name = '',
    department = '',
    match = 0,
    keywords: courseKeywords = [],
  } = course

  return (
    <>
      <ScrollContentBody className="detail-course">
        <NavBar>
          <Container>
            <Link to="/courses">
              <BackButton>
                <IconLeft />
              </BackButton>
            </Link>
          </Container>
        </NavBar>

        <ContCourseInfo>
          <Container className="detail-container">
            <PStyled className="small">{department}</PStyled>
            <H1Styled>{name}</H1Styled>
            <Progress value={match} />
          </Container>
        </ContCourseInfo>

        <Container>
          <MarginTB30>
            <ul className="no-list">
              {
                courseKeywords.map(keyword => {
                  const klass = keywords.includes(keyword) ? 'active' : ''
                  return (
                    <KeywordCont key={keyword} className={klass}>
                      <div className="keyword">
                        {keyword}
                      </div>
                      {
                        !!klass ? <IconCheck className="check" /> : <IconCross className="cross" />
                      }
                    </KeywordCont>
                  )
                })
              }
            </ul>
          </MarginTB30>
        </Container>
      </ScrollContentBody>
    </>
  );
}

export default CourseDetail