import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import CourseDetail from "./pages/CourseDetail";
import Courses from "./pages/Courses";
import Quiz from "./pages/Quiz";
import Welcome from "./pages/Welcome";
import Contacts from "./pages/Contacts";
import './App.scss'
import { KeywordsProvider } from "./providers/keywordsProviders";
import CookieBanner from './components/CookieBanner'

export default function Wordsmatch() {
  return (
    <KeywordsProvider>
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Welcome />
            </Route>
            <Route exact path="/quiz">
              <Quiz />
            </Route>
            <Route exact path="/contacts">
              <Contacts />
            </Route>
            <Route exact path="/courses">
              <Courses />
            </Route>
            <Route exact path="/course-detail/:id">
              <CourseDetail />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Router>

      <CookieBanner />
    </KeywordsProvider>
  );
}
