import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavBar, ScrollContentBody, Footer, Container, ButtonLogin, H1Styled, GlobalStyle } from "../styled-components";
import { ReactComponent as LogoHdemia } from '../images/logo-hdemia.svg';
import { ReactComponent as IconRight } from '../images/icn_right_big.svg';
import { ReactComponent as Student } from '../images/student_2.svg';
import { version } from '../../package.json';
import { useLocation } from "react-router-dom";

function Welcome() {

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  useEffect(()=>{
    if (searchParams.get('token')) {
      window.localStorage.setItem("token", true)
    } else {
      window.localStorage.setItem("token", false)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <ScrollContentBody className="welcome">
        <NavBar className="bg-trasparent">
          <LogoHdemia className="logo-hdemia u-center-horizontal" />
        </NavBar>

        <Container className="text-center">
          <H1Styled className="m-tb-10">Se sei arrivato qui<br/> è perché sei incuriosito dalle mille forme dell’arte.<br/>Quale viaggio ti aspetta?<br />Scopriamolo insieme...</H1Styled>
          <Link to="/quiz">
            <ButtonLogin type="button" className="">Inizia subito <IconRight /></ButtonLogin>
          </Link>
        </Container>

        <Footer className="no-padding">
          <Student className="u-center-horizontal" />
          <div className="blue-footer">
            <p>v. {version}</p>
          </div>
        </Footer>
      </ScrollContentBody>
    </>
  );
}

export default Welcome
